<template>
    <div class="card-body">
        <div class="row mt-2">
            <div class="col-sm-12">
                <table class="table table-sm table-bordered table-striped">
                    <thead>
                        <tr>
                            <th class="text-center">Denominations</th>
                            <th class="text-center">Opening</th>
                            <th class="text-center">BuyIn</th>
                            <th class="text-center">CashOut</th>
                            <th class="text-center">Experience Fees</th>
                            <th class="text-center">Tips</th>
                            <th class="text-center">Inward</th>
                            <th class="text-center">Outward</th>
                            <th class="text-center">Closing</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(denomination,key) in denominations" :key="key">
                            <td class="text-center">{{ denomination.denomination_value }}</td>
                            <td class="text-center">{{ denomination.open }}</td>
                            <td class="text-center">{{ denomination.buy_in }}</td>
                            <td class="text-center">{{ denomination.cash_out }}</td>
                            <td class="text-center">{{ denomination.exp_fees }}</td>
                            <td class="text-center">{{ denomination.tips }}</td>
                            <td class="text-center">{{ denomination.ex_in }}</td>
                            <td class="text-center">{{ denomination.ex_out }}</td>
                            <td class="text-center">{{ denomination.current }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Summary",
        props: {
            session_log_id: {
                default: null
            }
        },
        data() {
            return {
                session:{
                    branch_id:this.$store.getters.user.branch_id,
                    session_log_id:''
                },
                denominations: [],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.session.session_log_id = vm.session_log_id
        },
        methods: {
            getDenominations() {
                let vm = this;
                if (vm.$store.getters.session_denominations.length > 0) {
                    vm.denominations = vm.$store.getters.session_denominations
                }
                else {
                    let uri = { uri: "getSessionDenominations", data: vm.session  };
                    vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.denominations = response.data;
                        vm.$store.dispatch('setSessionDenominations', vm.denominations);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            }
        }
    };
</script>
