<template>
    <form @submit.prevent="addSession">
        <div class="card-body">
            <div class="row mt-2">
                <div class="col-sm-12">
                    <table class="table table-sm table-bordered table-striped">
                        <thead>
                            <tr>
                                <th colspan="3" class="text-center">Outward</th>
                                <th colspan="3" class="text-center">Inward</th>
                            </tr>
                            <tr>
                                <th class="text-center">Denomination</th>
                                <th class="text-center">Quantity</th>
                                <th class="text-center">Total</th>
                                <th class="text-center">Denomination</th>
                                <th class="text-center">Quantity</th>
                                <th class="text-center">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(denomination,key) in denominations" :key="key">
                                <td class="text-center">{{ denomination.denomination_value }}</td>
                                <td>
                                    <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.to_quantity}" placeholder="Quantity" v-model="denomination.to_quantity" @keyup="checkToDenomination(denomination)" />
                                </td>
                                <td class="text-center">
                                    {{ denomination.exchange_amount = (denomination.to_quantity * denomination.denomination_value).toFixed(2) }}
                                </td>
                                <td class="text-center">{{ denomination.denomination_value }}</td>
                                <td>
                                    <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.quantity}" placeholder="Quantity" v-model="denomination.quantity" @keyup="checkDenomination(denomination)" />
                                </td>
                                <td class="text-center">
                                    {{ denomination.amount = (denomination.quantity * denomination.denomination_value).toFixed(2) }}
                                </td>
                            </tr>
                            <tr>
                                <th class="text-center" colspan="3">{{ exchangeAmount }}</th>
                                <th class="text-center" colspan="3">{{ amount }}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card-footer" v-if=" amount!=0 && amount == exchangeAmount">
            <div class="float-right">
                <button class="btn btn-sm btn-outline-success" type="submit">
                    <span> <i class="fas fa-save"></i> Submit </span>
                </button>
            </div>
        </div>
    </form>
</template>

<script>
    import moment from 'moment'
    export default {
        name: "Session.Create",
        props: {
            session_log_id: {
                default: null
            }
        },
        data() {
            return {
                session: {
                    branch_id: this.$store.getters.user.branch_id,
                    user_id: this.$store.getters.user.user_id,
                    session_log_id:'',
                    session_type: "Exchange Chips",
                    session_date: moment().format('YYYY-MM-DD'),
                    session_time: moment().format('HH:mm:ss'),
                    note: "",
                    denominations:[]
                },
                denominations:[],
                errors: [],
            };
        },
        computed: {
            amount: function () {
                let vm = this;
                return  vm.denominations.reduce((prev, cur) => +prev + +cur.amount, 0);
            },
            exchangeAmount: function () {
                let vm = this;
                return  vm.denominations.reduce((prev, cur) => +prev + +cur.exchange_amount, 0);
            }
        },
        mounted() {
            let vm = this;
            vm.session.session_log_id = vm.session_log_id
            setInterval(vm.setSessionTime, 1000);
        },
        methods: {
            setSessionTime() {
                let vm = this;
                vm.session.session_time = moment().format('HH:mm');
            },
            getDenominations() {
                let vm = this;
                if (vm.$store.getters.branch_denominations.length > 0) {
                    vm.denominations = vm.$store.getters.branch_denominations
                }
                else{
                    let uri = { uri: "getBranchDenominations", data: vm.session  };
                    vm.$store.dispatch("post", uri)
                    .then(function (response) {
                        vm.denominations = response.data;
                        vm.$store.dispatch('setBranchDenominations', vm.denominations);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            checkDenomination(denomination) {
                let vm = this;
                if (denomination.denomination_quantity < denomination.quantity) {
                    vm.$store.dispatch("error", 'Please enter valid quantity');
                    return denomination.quantity = 0;
                }
            },

            checkToDenomination(denomination) {
                let vm = this;
                if (denomination.denomination_quantity < denomination.to_quantity) {
                    vm.$store.dispatch("error", 'Please enter valid quantity');
                    return denomination.to_quantity = 0;
                }
            },

            addSession() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.session.denominations = vm.denominations;
                let uri = { uri: "addSession", data: vm.session };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Session is successfully created");
                    vm.discard()
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            discard() {
                let vm = this;
                vm.denominations = [];
                vm.session.denominations = [];
                vm.errors = [];
                vm.getDenominations()
            }
        },
    };
</script>
